.Toastify__toast {
  border-radius: 0 !important;
}

.Toastify__toast-body {
  margin: 0;
  font-family: Poppins;
}
.Toastify__toast > button {
  margin: 4px 4px 0 0;
}
