.assessment-solver {
  display: grid;
  gap: 10px;
  grid-template-columns: 50% 50%;
  grid-template-rows: calc(100vh - var(--navbar-height) - 35px);
  grid-template-areas: "assessment code-editor";
}

.assessment {
  grid-area: assessment;
  overflow-y: auto;
}

.code-editor {
  grid-area: code-editor;
}

@media screen and (max-width: 1000px) {
  .assessment-solver {
    grid-template-rows: calc(100vh - var(--navbar-height) - 20px) calc(
        100vh - var(--navbar-height) - 20px
      );
    grid-template-columns: 100%;
    grid-template-areas:
      "assessment"
      "code-editor";
  }
}
